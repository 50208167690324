import styled from "styled-components";

import ButtonNoBorder from "../Buttons/ButtonNoBorder/ButtonNoBorder";

const BigButton = styled(ButtonNoBorder)`
  margin: 1rem;
  background-color: red;
  border-radius: 1rem;
`;

const JuhQSpeechButton = (): JSX.Element => {
  const speak = () => {
    setTimeout(() => {
      const text = Array(100).fill("Juhku").join(" ");
      const s = new SpeechSynthesisUtterance(text);
      s.lang = "fi-FI";
      speechSynthesis.speak(s);
    }, 1000);

    const text = "I told you not to click me!";
    const s = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(s);
  };

  return (
    <BigButton type="button" onClick={speak}>
      Do not click me
    </BigButton>
  );
};

export default JuhQSpeechButton;
