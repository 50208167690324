import { memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import BackgroundMusic from "../BackgroundMusic/BackgroundMusic";
import Blink from "../Blink/Blink";
import ComicSans from "../ComicSans/ComicSans";
import DeploymentLinks from "../DeploymentLinks/DeploymentLinks";
import Fireworks from "../Fireworks/Fireworks";
import GradientText from "../GradientText/GradientText";
import JuhQLogo from "../JuhQ/JuhQLogo/JuhQLogo";
import JuhQSpeechButton from "../JuhQSpeechButton/JuhQSpeechButton";
import Marquee from "../Marquee/Marquee";
import UnderConstruction from "../UnderConstruction/UnderConstruction";

const Section = styled.section`
  color: white;
  font-size: calc(10px + 2vmin);
`;

const HomeHeader = styled.div`
  display: flex;
  justify-content: center;
`;

const Main = styled.main`
  display: flex;
  min-height: 90vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Link1 = styled(Link)`
  text-decoration: none;
`;

const Container = styled.div`
  display: flex;
  width: 90vw;
  margin: 0 auto;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  align-self: self-start;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const RightSide = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;

  @media (max-width: 480px) {
    width: 100%;
    order: -1;
  }
`;

const Home = (): JSX.Element => (
  <Section>
    <HomeHeader>
      <Marquee>
        <GradientText>
          Hello friend! Welcome to my personal homepage!{" "}
          <ComicSans>This is my little corner of the internet!</ComicSans>
        </GradientText>
      </Marquee>
    </HomeHeader>
    <h3>Warning! Might cause migraine.</h3>
    <Main>
      <Container>
        <LeftSide>
          <UnderConstruction />
          <Fireworks />
          <Blink>Cool beans</Blink>
          <DeploymentLinks />
        </LeftSide>
        <RightSide>
          <JuhQLogo />
          <p>
            <Link1 to="/mesmerizing">🤯</Link1>
            <Link1 to="/ufo">👽</Link1>
            <Link1 to="/xfiles">🛸</Link1>
          </p>
          <BackgroundMusic />
        </RightSide>
      </Container>
      <JuhQSpeechButton />
    </Main>
  </Section>
);

export default memo(Home);
